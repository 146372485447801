import "styles/pages/comparison.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"

import { ComparisonNav, ComparisonList } from "components/Comparison"

const Comparison = () => {
  const title = "Porównywarka"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />
      <section className="comparison">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="comparison__mobile-info">
            <img
              src={require("assets/images/ico-scroll-mobile.svg").default}
              alt=""
            />{" "}
            Listwa przewijana na boki
          </div>
          <div className="comparison__wrapper">
            <div className="row">
              <div className="col-lg-3 col-nav">
                <ComparisonNav />
              </div>
              <ComparisonList />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Comparison
